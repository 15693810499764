<template>
  <div style="position: relative" class="has-background-light">
    <div
      class="card p-3"
      v-if="menu && openSeaDragon"
      :style="`position: fixed;z-index: 4; top: ${menu.y}px; left: ${menu.x}px;`"
    >
      <b-field>
        <b-button
          icon-left="pencil"
          @click="switchEditor"
          expanded
          :type="{ 'is-info': editorMode, 'is-primary': !editorMode }"
        >
          <span v-if="editorMode">{{t('openSea.readMode')}}</span>
          <span v-else>{{t('openSea.editMode')}}</span>
        </b-button>
        <b-button class="ml-1" @click="menu = null" icon-left="close"></b-button>
      </b-field>
    </div>
    <div
      class="card p-3"
      style="
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 3;
        min-width: 8rem;
        overflow:visible;
      "
    >
      <b-field>
        <b-button @click="goBack" icon-left="chevron-left" expanded>{{t('common.return')}}</b-button>
      </b-field>
      <b-field class="pb-0 mb-0" expanded :label="t('deepzoom.image')" label-position="on-border">
        <b-select expanded :placeholder="t('common.choose')" v-model="currentImage" @input="imageChanged">
          <option v-for="item in images" :key="item.id" :value="item">{{ item.id }}</option>
        </b-select>
      </b-field>
      <b-field v-if="currentImage" class="mb-1 pb-0">
        <b-tooltip position="is-right">
          <template v-slot:content>
            <p>{{currentImage.title}}</p>
            <p>{{currentImage.titleEn}}</p>
          </template>
          <p class="subtitle is-6">{{t('forms.name')}}</p>
        </b-tooltip>
      </b-field>
      <b-field v-if="currentLayer != null" class="mb-1 pb-0">
        <b-tooltip position="is-right">
          <template v-slot:content>
            <p>{{currentLayer.title}}</p>
            <p>{{currentLayer.titleEn}}</p>
          </template>
          <p class="subtitle is-6">{{t('deepzoom.layer')}}</p>
        </b-tooltip>
      </b-field>
      <b-collapse 
            v-if="currentImage || mediaImages.length > 0 || openSeaDragon || (classId && !onlyShow)"
            :open="true" 
            position="is-bottom" 
            aria-id="contentIdForA11y4">
            <template #trigger="props">
                <a
                    class="pt-0 mt-0"
                    aria-controls="contentIdForA11y4"
                    :aria-expanded="props.open">
                    <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                    {{ !props.open ? t('common.more') : t('common.fewer') }}
                </a>
            </template>
            <template>
              <div v-if="currentImage" class="mb-3">
                <template v-if="isAdmin || isTeacher">
                  <b-field class="mb-1 pb-0">
                    <p class="subtitle is-6">{{t('forms.nr')}}: {{ currentImage.id }}</p>
                  </b-field>
                  
                  <b-field class="mt-0 pb-0">
                    <p class="subtitle is-6">{{t('forms.category')}}: {{ currentImage.category }}</p>
                  </b-field>                  
                </template>
                <b-field v-if="isAdmin || isTeacher || imageId != null">
                    <b-button
                      @click="isOpenEditImageInfo = !isOpenEditImageInfo"
                      icon-left="information-outline"
                      expanded
                    >{{t('common.moreInformation')}}</b-button>
                  </b-field>
                <b-field v-for="item in currentImage.layers" :key="item.index" class="mb-1">
                  <b-radio-button expanded :native-value="item" v-model="currentLayer">{{ item.title }}</b-radio-button>
                </b-field>
              </div>
              <b-field expanded :label="t('deepZoom.multimedia')" v-if="mediaImages.length > 0" label-position="on-border">
                <b-select expanded :placeholder="t('common.choose')" v-model="currentMedia" @input="mediaSelect">
                  <option v-for="item in mediaImages" :key="item" :value="item">{{ item }}</option>
                </b-select>
              </b-field>

              <b-field v-if="onlyShow" expanded :label="t('openSea.lectureShapes')" label-position="on-border">
                <b-select expanded :placeholder="t('common.choose')" @input="shapeSelect">
                  <option
                    v-for="(item, index) in layerShapes"
                    :key="index"
                    :value="item.shapes"
                  >{{t('openSea.shapeNo')}} {{ index + 1 }}</option>
                </b-select>
              </b-field>

              <b-button
                v-if="openSeaDragon"
                icon-left="pencil"
                @click="switchEditor"
                expanded
                :type="{ 'is-info': editorMode, 'is-primary': !editorMode }"
              >
                <span v-if="editorMode">{{t('openSea.readMode')}}</span>
                <span v-else>{{t('openSea.editMode')}}</span>
              </b-button>

              <b-field v-if="editorMode && (isTeacher || isAdmin)">
                <b-input v-model="labelPath" :placeholder="t('openSea.addLabel')"></b-input>
                <b-button icon-left="plus" @click="addLabel"></b-button>
              </b-field>
              <b-button v-if="editorMode" icon-left="eraser" @click="eraserShape" expanded>{{t('openSea.eraserShape')}}i</b-button>

              <b-field class="mt-3" v-if="classId && !onlyShow">
                <b-checkbox v-model="online">{{t('openSea.online')}}</b-checkbox>
              </b-field>
            </template>
      </b-collapse>
    </div>
    <div
      id="openseadragon"
      style="width: 100%; height: calc(100vh - 50px)"
      @contextmenu="rightClick($event)"
    ></div>
    <div style="display: none">
      <button id="mockupButton0"></button>
      <button id="mockupButton1"></button>
    </div>
    <b-modal has-modal-card v-model="isOpenEditImageInfo" aria-role="dialog" aria-modal>
      <template v-if="currentImage" #default="props">
        <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{t('deepzoom.sampleNo')}} {{ currentImage.id }}</p>
          <button type="button" class="delete is-primary" @click="props.close" />
        </header>
        <section class="modal-card-body">
          <p>{{t('forms.name')}}: {{ currentImage.title }}</p>
          <p>{{t('forms.nameInEnglish')}}: {{ currentImage.titleEn }}</p>
          <p class="pb-1">{{t('forms.description')}}: {{ currentImage.description }}</p>
          <p class="pb-1">{{t('forms.descriptionInEnglish')}}: {{ currentImage.descriptionEn }}</p>
          <div v-for="layer in currentImage.layers" :key="layer.id" class="pb-2">
            <p><strong>{{t('deepzoom.numberLayer')}}:</strong> {{ layer.id }} <strong>{{t('deepzoom.titleLayer')}}:</strong> {{ layer.title }}</p>
            <p v-if="layer.titleEn != null && layer.titleEn.length>0"><strong>{{t('deepzoom.titleLayerEn')}}:</strong> {{ layer.titleEn }}</p>
            <p>{{t('deepzoom.descLayer')}}: {{ layer.description }}</p>
            <p v-if="layer.descriptionEn != null && layer.descriptionEn.length>0">{{t('deepzoom.descLayerEn')}}: {{ layer.descriptionEn }}</p>
          </div>
        </section>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="imageShow">
      <template v-if="currentUrl">
        <p class="image is-5by3">
          <img :src="currentUrl" />
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SERVER } from '@/store/config_apis'

import CommonMixins from "@/mixins/commons";
import { Apis } from "../../store/config_apis";
import { Action } from "../../store/config_actions";
import { fabric } from "fabric";
import { Mutation } from '../../store/config_mutations';
const signalR = require("@aspnet/signalr");
//import UserFeedback from "@/components/feedbacks/UserFeedback";

export default {
  name: "OpenSeaDemo",
  mixins: [CommonMixins],
  components: {
    //UserFeedback,
  },

  data: function () {
    return {
      //classId: "lorem-ipsum",
      openSeaDragon: null,
      fabrics: null,
      connection: null,
      zoomValue: 1,
      editorMode: false,
      images: [],
      currentImage: null,
      currentLayer: null,
      isLoading: false,
      online: true,
      lastPath: null,
      labelPath: "",
      menu: null,
      currentClassroom: null,
      deepZoomConfig: null,
      isOpenEditImageInfo: false,
      mediaImages: [],
      imageShow: false,
      currentMedia: null,
      currentUrl: null,
      shapeDatas: [],
      newShape: {
        image: null,
        shapes: null,
      },
      oldCanvas: null,
    };
  },

  props: {
    classId: { type: String, required: false },
    onlyShow: { type: Boolean, required: false },
    imageId: { type: String, required: false },
  },

  watch: {
    /**
     * Watch connection state
     */
    "connection.state"(val) {
      if (val === 1) {
        this.mSuccess(this.t('openSea.synchronizationConected'));
      }
    },

    currentLayer(val, oldVal) {
      if (this.currentImage) {
        let page = this.currentImage.layers.indexOf(val);

        if (oldVal) {
          if (this.canvas && this.canvas.getObjects().length > 0) {
            this.shapeDatas.push({
              image: oldVal.id,
              shapes: JSON.stringify(this.canvas.toJSON()),
            });
          }
        }

        if (page > -1) {
          this.goToLayer(page);
        }
      }
    },

    imageShow(val) {
      if (val) {
        this.currentUrl = `${SERVER}/api/MediaFile/File/${this.currentMedia}`;
        if (this.isTeacher) {
          this.osyncSendMedia(this.currentMedia);
        }
      } else {
        this.currentMedia = null;
        if (this.isTeacher) {
          this.osyncSendMedia("");
        }
      }
    },

    classId() {
      if (this.classId != null && !this.onlyShow) {
        // this.osyncInitialize();
      }
    },
  },

  beforeDestroy() {
    if (this.isTeacher) {
      this.updateClassroom();
    }
  },

  mounted() {
    //this.openSeaInitialize();
    if (this.classId) {
      this.osyncInitialize();
      this.getClassroom();
    } else if (this.imageId) {
      let oneImage = [];
      oneImage.push(this.imageId);
      this.getDeepZoomConfigForClassroomImages(oneImage);
    } else {
      this.getDeepZoomConfig();
      //this.getImages();
    }
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    layerShapes() {
      if (this.currentClassroom && this.currentLayer) {
        let filtered = this.currentClassroom.shapeData.filter(
          (shape) => shape.image === this.currentLayer.id
        );
        return filtered;
      } else return [];
    },
  },

  methods: {
    goBack() {
      if(this.imageId != null){
        this.$store.commit(Mutation.DEEPZOOM_SET_ISBACK, true);
      }
      this.$router.go(-1);
    },

    shapeSelect(val) {
      if (this.canvas) {
        this.canvas.remove(...this.canvas.getObjects());
        this.canvas.loadFromJSON(val, this.canvas.renderAll.bind(this.canvas));
      }
    },

    rightClick(event) {
      this.menu = { x: event.clientX, y: event.clientY };
      event.preventDefault();
    },

    layerChanged(value) {
      this.openSeaInitialize(value);
    },

    imageChanged(value) {
      this.currentImage = value;
      if (value.layers.length > 0) {
        if (this.isAdmin || this.isTeacher) {
          this.osyncSendImage(value);
        }

        let imgs = [];

        value.layers.forEach((img) => {
          imgs.push(
            `${SERVER}/api/DeepZoomConfig/Image/${value.id}/${img.id}.xml`
          );
        });

        if (this.canvas) {
          this.oldCanvas = JSON.stringify(this.canvas.toJSON());
          this.canvas.remove(...this.canvas.getObjects());
        }

        this.currentLayer = value.layers[0];
        this.openSeaInitialize(imgs);
      }
    },

    goToLayer(index) {
      if (this.openSeaDragon) {
        this.openSeaDragon.goToPage(index);
        if (this.isAdmin || this.isTeacher) {
          this.lastPath = null;
          this.osyncSendLayer(this.currentImage.layers[index]);
        }
      }
    },

    /**
     * OPENSEA
     * Triggers zoom and pan to provided coords.
     */
    zoom: function (x, y, zoom) {
      if (this.openSeaDragon) {
        // eslint-disable-next-line no-undef
        this.openSeaDragon.viewport.panTo(new OpenSeadragon.Point(x, y));
        this.openSeaDragon.viewport.zoomTo(zoom);
      }
    },

    // getImages() {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch(Action.IMAGES_GET_LIST)
    //     .then((response) => {
    //       this.isLoading = false;
    //       this.images = response;
    //     })
    //     .catch((error) => {
    //       this.mDangerSnack(error);
    //       this.isLoading = false;
    //     });
    // },

    switchEditor() {
      this.menu = null;
      this.editorMode = !this.editorMode;

      if (this.editorMode) {
        this.canvas.isDrawingMode = true;
        this.openSeaDragon.setMouseNavEnabled(false);
        this.openSeaDragon.outerTracker.setTracking(false);
      } else {
        this.canvas.isDrawingMode = false;
        this.openSeaDragon.setMouseNavEnabled(true);
        this.openSeaDragon.outerTracker.setTracking(true);
      }
    },

    /**
     * OPENSEA
     * Initialize OpenSeaDragon component and configure events.
     */
    openSeaInitialize(image) {
      this.menu = null;
      if (this.openSeaDragon === null) {
        // OpenSeaDragon init.
        // eslint-disable-next-line no-undef
        this.openSeaDragon = OpenSeadragon({
          id: "openseadragon",
          tileSources: image,
          defaultZoomLevel: 1.05,
          showNavigationControl: false,
          showNavigator: true,
          sequenceMode: true,
          preserveViewport: true,
          nextButton: "mockupButton0",
          previousButton: "mockupButton1",
        });//
        // maxZoomPixelRatio: 2,


        this.openSeaDragon.addHandler("resize", this.resized);

      } else {
        //this.canvas.remove(...this.canvas.getObjects());
        this.lastPath = null;
        this.openSeaDragon.open(image);
      }


      // Initialize FabricsJS overlay
      this.fabrics = this.openSeaDragon.fabricjsOverlay({ scale: 1000 });

      // Get canvas instance
      this.canvas = this.fabrics.fabricCanvas();

      this.openSeaDragon.world.resetItems();

      // Paint it red!
      this.canvas.freeDrawingBrush.color = "red";
      if (this.oldCanvas) {
        this.canvas.loadFromJSON(
          this.oldCanvas,
          this.canvas.renderAll.bind(this.canvas)
        );
      }

      // Configure on path added event.
      this.canvas.on("path:created", this.pathCreated);

      // Parent component reference
      var thisVue = this;

      if (thisVue.classId && !thisVue.onlyShow) {
        if (thisVue.isAdmin || thisVue.isTeacher) {
          // Send coords on image pan or zoom + navigator mouseup
          this.openSeaDragon.addHandler("zoom", this.osyncSendViewport);
          this.openSeaDragon.addHandler(
            "canvas-release",
            this.osyncSendViewport
          );
          this.openSeaDragon.addHandler("navigator-click", function () {
            // Delay because of pan animation.
            setTimeout(thisVue.osyncSendViewport, 250);
          });
        }
      }
    },

    resized() {

    },

    /**
     * Event on SVG path created.
     */
    pathCreated(item) {
      this.lastPath = item.path.path;

      this.osyncSendShapes(JSON.stringify(this.canvas.toJSON()));
    },

    eraserShape() {
      if (this.canvas) {
        if (this.canvas.getObjects().length > 0) {
          this.shapeDatas.push({
            image: this.currentLayer.id,
            shapes: JSON.stringify(this.canvas.toJSON()),
          });
        }
        this.canvas.remove(...this.canvas.getObjects());
        this.osyncSendShapes(JSON.stringify(this.canvas.toJSON()));
      }
    },

    /**
     * Add label to last created path
     */
    addLabel() {
      if (this.lastPath) {
        let arrayPath = this.lastPath;
        var xStart = arrayPath[0][1];
        var ystart = arrayPath[0][2];

        var text = new fabric.IText(this.labelPath, {
          left: xStart,
          top: ystart,
          fontSize: 12,
          fontFamily: "Verdana",
          fill: "black",
          visible: true,
        });

        this.canvas.add(text);
        this.osyncSendShapes(JSON.stringify(this.canvas.toJSON()));
      } else {
        this.mDangerSnack(this.t('openSea.noObject'));
      }
    },

    mediaSelect() {
      this.imageShow = !this.imageShow;
    },

    /**
     * SIGNALR
     * Initialize SignalR connection and configure events.
     */
    osyncInitialize() {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(Apis.SYNC_DEMO)
        .configureLogging(signalR.LogLevel.Information)
        .build();

      // Parent component reference
      var thisVue = this;

      // Start connection.
      this.connection.start().catch(function () {
        thisVue.mDanger(this.t('openSea.synchronizationUnavailable'));
      });

      // Action on reciving ViewportUpdated signal
      this.connection.on("ViewportUpdated", function (classId, x, y, zoom) {
        if (
          thisVue.online &&
          thisVue.classId === classId &&
          !thisVue.onlyShow
        ) {
          if (!thisVue.isAdmin && !thisVue.isTeacher) {
            thisVue.zoom(x, y, zoom);
          }
        }
      });

      // Action on reciving ImageChanged signal
      this.connection.on("ImageChanged", function (classId, image) {
        if (
          thisVue.online &&
          thisVue.classId === classId &&
          !thisVue.onlyShow
        ) {
          if (!thisVue.isAdmin && !thisVue.isTeacher) {
            let imageConfig = JSON.parse(image);
            thisVue.imageChanged(imageConfig);
            //thisVue.canvas.remove(...thisVue.canvas.getObjects());
            thisVue.lastPath = null;
          }
        }
      });

      // Action on reciving LayerChanged signal
      this.connection.on("LayerChanged", function (classId, lay) {

        let layer = JSON.parse(lay);
        if (
          thisVue.online &&
          thisVue.classId === classId &&
          !thisVue.onlyShow
        ) {
          if (!thisVue.isAdmin && !thisVue.isTeacher) {

            if (thisVue.currentImage) {

              let abc = thisVue.currentImage.layers.find(x => x.id == layer.id)
              let page = thisVue.currentImage.layers.findIndex(x => x.id == layer.id)

              if (abc && page > -1) {
                thisVue.currentLayer = abc;
                thisVue.lastPath = null;
                thisVue.goToLayer(page);
              }
            }
          }
        }
      });

      // Action on reciving DrawingsUpdated signal
      this.connection.on("DrawingsUpdated", function (classId, shapes) {
        if (thisVue.online && thisVue.classId === classId && !this.onlyShow) {
          if (!thisVue.isAdmin && !thisVue.isTeacher) {
            thisVue.canvas.remove(...thisVue.canvas.getObjects());
            thisVue.lastPath = null;
            thisVue.canvas.loadFromJSON(
              shapes,
              thisVue.canvas.renderAll.bind(thisVue.canvas)
            );
          }
        }
      });

      // Action on reciving LayerChanged signal
      this.connection.on("MediaShowed", function (classId, image) {
        if (thisVue.online && thisVue.classId === classId && !this.onlyShow) {
          if (!thisVue.isAdmin && !thisVue.isTeacher) {
            thisVue.currentMedia = image;
            thisVue.imageShow = !thisVue.imageShow;
          }
        }
      });
    },

    /**
     * SIGNALR
     * Send viewport data.
     */
    osyncSendViewport() {
      if (this.online && this.classId && !this.onlyShow) {
        var center = this.openSeaDragon.viewport.getCenter();
        var zoom = this.openSeaDragon.viewport.getZoom();
        this.zoomValue = zoom;

        this.connection
          .invoke("UpdateViewport", this.classId, center.x, center.y, zoom)
          .catch(function () {
            if (this) {
              this.mDanger(this.t('openSea.synchronizationError'));
            }
          });
      }
    },

    /**
     * SIGNALR
     * Send viewport data.
     */
    osyncSendImage(image) {
      if (this.online && this.classId && !this.onlyShow) {
        this.connection
          .invoke("ChangeImage", this.classId, JSON.stringify(image))
          .catch(function () {
            if (this) {
              this.mDanger(this.t('openSea.synchronizationError'));
            }
          });
      }
    },

    /**
     * SIGNALR
     * Send viewport data.
     */
    osyncSendLayer(layer) {
      if (this.online && this.classId && !this.onlyShow) {
        this.connection
          .invoke("ChangeLayer", this.classId, JSON.stringify(layer))
          .catch(function () {
            if (this) {
              this.mDanger(this.t('openSea.synchronizationError'));
            }
          });
      }
    },

    /**
     * SIGNALR
     * Send shapes data.
     */
    osyncSendShapes(shapes) {
      if (this.online && this.classId && !this.onlyShow) {
        this.connection
          .invoke("UpdateDrawings", this.classId, shapes)
          .catch(function () {
            if (this) {
              this.mDanger(this.t('openSea.synchronizationError'));
            }
          });
      }
    },

    /**
     * SIGNALR
     * Send shapes data.
     */
    osyncSendMedia(image) {
      if (this.online && this.classId && !this.onlyShow) {
        this.connection
          .invoke("ShowMedia", this.classId, image)
          .catch(function () {
            if (this) {
              this.mDanger();
            }
          });
      }
    },

    /** Get current classroom */
    getClassroom() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ONE_BY_ID, this.classId)
        .then((payload) => {
          this.currentClassroom = payload;
          this.mediaImages = payload.media;
          this.getDeepZoomConfigForClassroomImages(payload.images);
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    /** Get current deepZooomConfig */
    getDeepZoomConfig() {
      this.isLoading = true;
      if (this.$store.state.deepzoom.config) {
        this.deepZoomConfig = this.$store.state.deepzoom.config;
        this.images = this.deepZoomConfig.images;
      } else {
        this.$store
          .dispatch(Action.DEEPZOOM_CONFIG_GET)
          .then((payload) => {
            this.deepZoomConfig = payload;
            this.images = payload.images;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }
    },

    /** Get current deepZooomConfig */
    getDeepZoomConfigForClassroomImages(idImages) {
      this.isLoading = true;
      let send = { images: idImages };
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_GROUP, send)
        .then((payload) => {
          this.deepZoomConfig = payload;
          this.images = payload.images;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    updateClassroom() {
      if (this.currentClassroom && !this.onlyShow && this.isTeacher) {
        this.currentClassroom.shapeData = this.shapeDatas;
        this.isLoading = true;
        this.$store
          .dispatch(Action.CLASSROOM_UPDATE, this.currentClassroom)
          .then(() => {
            this.mSuccessSnack(this.t("common.changesSaved"));
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }
    },
  },
};
</script>

<style scoped>
.darkback {
  background-color: rgba(0, 0, 0, 0.322);
}
.delete {
  background-color: #f03a5f;
}
</style>