<template>
  <div class="home">
    <template v-if="$route.params.onlyShow">
      <OpenSeaViewer
        :classId="$route.params.classId"
        :onlyShow="$route.params.onlyShow"
      />
    </template>
    <template v-else>
      <OpenSeaViewer :classId="$route.params.classId" :imageId="$route.params.imageId" />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import OpenSeaViewer from "@/components/opensea/OpenSeaViewer.vue";

export default {
  name: "OpenSeaDemoView",
  components: {
    OpenSeaViewer,
  },
};
</script>
